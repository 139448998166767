<template lang="pug">
head-meta(title-page="Спасибо за заявку на сайте CHIEDOHOME.COM", description="Успешное оформление заявки. Менеджер скоро свяжется с вами" )
thanks-404(mod="thanks")
</template>

<script>
import Thanks404 from '../components/Thanks404'
import HeadMeta from '../components/_layout/HeadMeta'

export default {
  name: 'ThanksPage',
  components: { HeadMeta, Thanks404 }
}
</script>
